.VerticalList {
    background-color: #333;
    flex: 1; /* Take up all remaining space */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 10px;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  }
  
  .VerticalList-container {
    display: flex;
    flex-direction: column;
  }
  
  .VerticalList-item {
    margin-bottom: 5px; /* Space between items */
    background-color: #444;
    height: 150px; /* Adjust as needed */
    border-bottom: 2px solid #fff; /* White lines separating items */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  