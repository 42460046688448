/* Import Fira Code font */
@font-face {
    font-family: 'FiraCode';
    src: url('/FiraCode-Regular.ttf') format('truetype'); /* Adjust the path as needed */
  }
  
  /* Reset default margins and paddings */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Set body background, text color, and font */
  body {
    font-family: 'FiraCode', monospace; /* Fallback to monospace in case the custom font doesn't load */
    color: #fff; /* White text color */
    background-color: #333; /* Dark background */
  }
  