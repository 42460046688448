.HorizontalList {
    background-color: #333;
    padding: 10px;
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  }
  
  .HorizontalList-container {
    display: inline-flex;
  }
  
  .HorizontalList-item {
    flex: 0 0 auto;
    margin-right: 5px; /* Space between items */
    background-color: #444;
    height: 100px; /* Adjust as needed */
    border-right: 2px solid #fff; /* White lines separating items */
    min-width: 100px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  