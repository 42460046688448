.App {
    background-color: #333; /* Dark background for a sleek look */
    color: #fff; /* White text for contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    padding: 20px;
  }
  
  .App-header {
    position: absolute;
    top: 10px;
    width: 100%;
    text-align: center;
  }
  
  .App-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack horizontally and vertically centered */
  }
  
  /* Styling for the chronograph face */
  .chronograph-face {
    background-color: #444;
    border-radius: 50%;
    border: 5px solid #bbb; /* Metallic border */
    box-shadow: 0px 0px 20px rgba(0,0,0,0.7);
    padding: 50px; /* Padding inside the chronograph face */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  